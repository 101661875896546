import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Layout from '../components/Layout';
import { COLORS } from '../styles/constants';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: 'white',
    },
    ul: {
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  root: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '5.5rem',
    },
  },
  title: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  item: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  link: {
    width: '100%',
    textDecoration: 'none',
  },
  heroContent: {},
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 330,
  },
}));

const tiers = [
  {
    title: 'Try it out',
    price: '0',
    per: 'month',
    link: 'https://app.pushback.io/tryitout',
    description: [
      '100 messages',
      '1 synchronous connection',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Try it out',
    buttonVariant: 'outlined',
  },
  {
    title: 'Individual Use',
    subheader: 'Most popular',
    price: '1',
    per: 'month*',
    link: 'https://app.pushback.io/login',
    description: [
      'Unlimited messages',
      '5 synchronous connections',
      'Share Links',
      'Webhooks',
      '* billed annually at $12/year',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Small Business',
    price: '5',
    per: 'mo',
    per: 'month',
    link: 'mailto: support@pushback.io',
    description: [
      'Unlimited messages',
      '100 synchronous connections',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <Layout>
      {/* Hero unit */}
      <div className={classes.red}>
        <Container maxWidth="lg" component="main" className={classes.root}>
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography variant="h1" align="center" className={classes.title}>
              Pricing
            </Typography>
          </Container>
          {/* End hero unit */}
          <Container maxWidth="lg" component="main">
            <Grid container spacing={6} alignItems="flex-end">
              {tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === 'Enterprise' ? 12 : 6}
                  md={4}
                >
                  <Card className={classes.card}>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.cardHeader}
                    />
                    <CardContent style={{ flex: 1 }}>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        >
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          /{tier.per}
                        </Typography>
                      </div>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            className={classes.item}
                            variant="body1"
                            align="left"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions>
                      <a className={classes.link} href={tier.link}>
                        {tier.buttonVariant === 'outlined' ? (
                          <Button
                            style={{
                              border: `1px solid ${COLORS.pushback}`,
                              color: COLORS.pushback,
                              marginBottom: 10,
                            }}
                            fullWidth
                            variant={tier.buttonVariant}
                          >
                            {tier.buttonText}
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: COLORS.pushback,
                              color: 'white',
                              marginBottom: 10,
                            }}
                            fullWidth
                            color="primary"
                          >
                            {tier.buttonText}
                          </Button>
                        )}
                      </a>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container>
      </div>
    </Layout>
  );
}
